// Generated by nuxt-prepare
export const okovi = [
  {
    "id": 76,
    "name": "Bravice za nameštaj",
    "slug": "bravice-za-namestaj",
    "description": "Pellentesque faucibus maximus tellus nec porttitor. Quisque cursus dignissim quam sit amet pharetra. Sed non sapien quis libero placerat efficitur. Praesent sit amet tincidunt dui.",
    "parent": 0,
    "count": 19,
    "image": {
      "id": 343,
      "src": "https://beta.stilles.rs/cms/wp-content/uploads/2024/06/bravice-za-namestaj.jpg",
      "thumbnail": "https://beta.stilles.rs/cms/wp-content/uploads/2024/06/bravice-za-namestaj-300x300.jpg",
      "srcset": "https://beta.stilles.rs/cms/wp-content/uploads/2024/06/bravice-za-namestaj.jpg 700w, https://beta.stilles.rs/cms/wp-content/uploads/2024/06/bravice-za-namestaj-464x325.jpg 464w, https://beta.stilles.rs/cms/wp-content/uploads/2024/06/bravice-za-namestaj-220x154.jpg 220w, https://beta.stilles.rs/cms/wp-content/uploads/2024/06/bravice-za-namestaj-600x420.jpg 600w",
      "sizes": "(max-width: 700px) 100vw, 700px",
      "name": "bravice-za-namestaj",
      "alt": ""
    },
    "review_count": 0,
    "permalink": "https://beta.stilles.rs/product-category/bravice-za-namestaj/"
  },
  {
    "id": 83,
    "name": "Klizni mehanizmi",
    "slug": "klizni-mehanizmi",
    "description": "",
    "parent": 0,
    "count": 218,
    "image": {
      "id": 3286,
      "src": "https://beta.stilles.rs/cms/wp-content/uploads/2024/07/klizni-mehanizmi.jpg",
      "thumbnail": "https://beta.stilles.rs/cms/wp-content/uploads/2024/07/klizni-mehanizmi-300x300.jpg",
      "srcset": "https://beta.stilles.rs/cms/wp-content/uploads/2024/07/klizni-mehanizmi.jpg 512w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/klizni-mehanizmi-464x464.jpg 464w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/klizni-mehanizmi-220x220.jpg 220w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/klizni-mehanizmi-300x300.jpg 300w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/klizni-mehanizmi-100x100.jpg 100w",
      "sizes": "(max-width: 512px) 100vw, 512px",
      "name": "klizni-mehanizmi",
      "alt": ""
    },
    "review_count": 0,
    "permalink": "https://beta.stilles.rs/product-category/klizni-mehanizmi/"
  },
  {
    "id": 79,
    "name": "Lajsne za nameštaj",
    "slug": "lajsne-za-namestaj",
    "description": "",
    "parent": 0,
    "count": 244,
    "image": {
      "id": 3302,
      "src": "https://beta.stilles.rs/cms/wp-content/uploads/2024/07/lajsna.jpg",
      "thumbnail": "https://beta.stilles.rs/cms/wp-content/uploads/2024/07/lajsna-300x300.jpg",
      "srcset": "https://beta.stilles.rs/cms/wp-content/uploads/2024/07/lajsna.jpg 512w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/lajsna-464x464.jpg 464w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/lajsna-220x220.jpg 220w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/lajsna-300x300.jpg 300w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/lajsna-100x100.jpg 100w",
      "sizes": "(max-width: 512px) 100vw, 512px",
      "name": "lajsna",
      "alt": ""
    },
    "review_count": 0,
    "permalink": "https://beta.stilles.rs/product-category/lajsne-za-namestaj/"
  },
  {
    "id": 115,
    "name": "Lepkovi",
    "slug": "lepkovi",
    "description": "",
    "parent": 0,
    "count": 55,
    "image": {
      "id": 3285,
      "src": "https://beta.stilles.rs/cms/wp-content/uploads/2024/07/lepak-e1721403403178.jpeg",
      "thumbnail": "https://beta.stilles.rs/cms/wp-content/uploads/2024/07/lepak-300x300.jpeg",
      "srcset": "",
      "sizes": "(max-width: 600px) 100vw, 600px",
      "name": "lepak",
      "alt": ""
    },
    "review_count": 0,
    "permalink": "https://beta.stilles.rs/product-category/lepkovi/"
  },
  {
    "id": 40,
    "name": "Nekategorizovano",
    "slug": "nekategorizovano",
    "description": "Okov za nameštaj koji nije razvrstan u druge,  već postojeće kategorije",
    "parent": 0,
    "count": 1926,
    "image": {
      "id": 3301,
      "src": "https://beta.stilles.rs/cms/wp-content/uploads/2024/07/nekategorizovano.jpg",
      "thumbnail": "https://beta.stilles.rs/cms/wp-content/uploads/2024/07/nekategorizovano-300x300.jpg",
      "srcset": "https://beta.stilles.rs/cms/wp-content/uploads/2024/07/nekategorizovano.jpg 512w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/nekategorizovano-464x464.jpg 464w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/nekategorizovano-220x220.jpg 220w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/nekategorizovano-300x300.jpg 300w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/nekategorizovano-100x100.jpg 100w",
      "sizes": "(max-width: 512px) 100vw, 512px",
      "name": "nekategorizovano",
      "alt": ""
    },
    "review_count": 0,
    "permalink": "https://beta.stilles.rs/product-category/nekategorizovano/"
  },
  {
    "id": 74,
    "name": "Nogice za nameštaj",
    "slug": "nogice-za-namestaj",
    "description": "Nogice za nameštaj su mali dodaci koji se pričvršćuju za dno stolova, stolica, fotelja i drugih komada nameštaja kako bi se podigao, omogućilo provetravanje ispod nameštaja, ili zaštitio pod od ogrebotina.",
    "parent": 0,
    "count": 197,
    "image": {
      "id": 3287,
      "src": "https://beta.stilles.rs/cms/wp-content/uploads/2024/07/3416029.jpg.webp",
      "thumbnail": "https://beta.stilles.rs/cms/wp-content/uploads/2024/07/3416029.jpg-300x300.webp",
      "srcset": "https://beta.stilles.rs/cms/wp-content/uploads/2024/07/3416029.jpg.webp 500w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/3416029.jpg-464x464.webp 464w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/3416029.jpg-220x220.webp 220w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/3416029.jpg-300x300.webp 300w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/3416029.jpg-100x100.webp 100w",
      "sizes": "(max-width: 500px) 100vw, 500px",
      "name": "3416029.jpg",
      "alt": ""
    },
    "review_count": 0,
    "permalink": "https://beta.stilles.rs/product-category/nogice-za-namestaj/"
  },
  {
    "id": 77,
    "name": "Nosači nameštaja",
    "slug": "nosaci-namestaja",
    "description": "",
    "parent": 0,
    "count": 192,
    "image": {
      "id": 3298,
      "src": "https://beta.stilles.rs/cms/wp-content/uploads/2024/07/nosaci.jpg",
      "thumbnail": "https://beta.stilles.rs/cms/wp-content/uploads/2024/07/nosaci-300x300.jpg",
      "srcset": "https://beta.stilles.rs/cms/wp-content/uploads/2024/07/nosaci.jpg 512w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/nosaci-464x464.jpg 464w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/nosaci-220x220.jpg 220w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/nosaci-300x300.jpg 300w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/nosaci-100x100.jpg 100w",
      "sizes": "(max-width: 512px) 100vw, 512px",
      "name": "nosaci",
      "alt": ""
    },
    "review_count": 0,
    "permalink": "https://beta.stilles.rs/product-category/nosaci-namestaja/"
  },
  {
    "id": 113,
    "name": "Oklagije",
    "slug": "oklagije",
    "description": "",
    "parent": 0,
    "count": 9,
    "image": {
      "id": 3300,
      "src": "https://beta.stilles.rs/cms/wp-content/uploads/2024/07/oklagija.jpg",
      "thumbnail": "https://beta.stilles.rs/cms/wp-content/uploads/2024/07/oklagija-300x300.jpg",
      "srcset": "https://beta.stilles.rs/cms/wp-content/uploads/2024/07/oklagija.jpg 512w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/oklagija-464x464.jpg 464w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/oklagija-220x220.jpg 220w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/oklagija-300x300.jpg 300w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/oklagija-100x100.jpg 100w",
      "sizes": "(max-width: 512px) 100vw, 512px",
      "name": "oklagija",
      "alt": ""
    },
    "review_count": 0,
    "permalink": "https://beta.stilles.rs/product-category/oklagije/"
  },
  {
    "id": 112,
    "name": "Podizni mehanizmi",
    "slug": "podizni-mehanizmi",
    "description": "",
    "parent": 0,
    "count": 5,
    "image": {
      "id": 3297,
      "src": "https://beta.stilles.rs/cms/wp-content/uploads/2024/07/podizaci.jpg",
      "thumbnail": "https://beta.stilles.rs/cms/wp-content/uploads/2024/07/podizaci-300x300.jpg",
      "srcset": "https://beta.stilles.rs/cms/wp-content/uploads/2024/07/podizaci.jpg 512w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/podizaci-464x464.jpg 464w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/podizaci-220x220.jpg 220w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/podizaci-300x300.jpg 300w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/podizaci-100x100.jpg 100w",
      "sizes": "(max-width: 512px) 100vw, 512px",
      "name": "podizaci",
      "alt": ""
    },
    "review_count": 0,
    "permalink": "https://beta.stilles.rs/product-category/podizni-mehanizmi/"
  },
  {
    "id": 116,
    "name": "Rozetne",
    "slug": "rozetne",
    "description": "",
    "parent": 0,
    "count": 6,
    "image": {
      "id": 3295,
      "src": "https://beta.stilles.rs/cms/wp-content/uploads/2024/07/rozetna.jpg",
      "thumbnail": "https://beta.stilles.rs/cms/wp-content/uploads/2024/07/rozetna-300x300.jpg",
      "srcset": "https://beta.stilles.rs/cms/wp-content/uploads/2024/07/rozetna.jpg 512w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/rozetna-464x464.jpg 464w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/rozetna-220x220.jpg 220w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/rozetna-300x300.jpg 300w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/rozetna-100x100.jpg 100w",
      "sizes": "(max-width: 512px) 100vw, 512px",
      "name": "rozetna",
      "alt": ""
    },
    "review_count": 0,
    "permalink": "https://beta.stilles.rs/product-category/rozetne/"
  },
  {
    "id": 84,
    "name": "Sistemi za spajanje nameštaja",
    "slug": "sistemi-za-spajanje-namestaja",
    "description": "",
    "parent": 0,
    "count": 12,
    "image": {
      "id": 3292,
      "src": "https://beta.stilles.rs/cms/wp-content/uploads/2024/07/drveni-tipl.jpg",
      "thumbnail": "https://beta.stilles.rs/cms/wp-content/uploads/2024/07/drveni-tipl-300x300.jpg",
      "srcset": "https://beta.stilles.rs/cms/wp-content/uploads/2024/07/drveni-tipl.jpg 600w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/drveni-tipl-464x464.jpg 464w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/drveni-tipl-220x220.jpg 220w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/drveni-tipl-300x300.jpg 300w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/drveni-tipl-100x100.jpg 100w",
      "sizes": "(max-width: 600px) 100vw, 600px",
      "name": "drveni-tipl",
      "alt": ""
    },
    "review_count": 0,
    "permalink": "https://beta.stilles.rs/product-category/sistemi-za-spajanje-namestaja/"
  },
  {
    "id": 80,
    "name": "Sudopere",
    "slug": "sudopere",
    "description": "",
    "parent": 0,
    "count": 217,
    "image": {
      "id": 3277,
      "src": "https://beta.stilles.rs/cms/wp-content/uploads/2024/07/10315-1.jpg",
      "thumbnail": "https://beta.stilles.rs/cms/wp-content/uploads/2024/07/10315-1-300x300.jpg",
      "srcset": "https://beta.stilles.rs/cms/wp-content/uploads/2024/07/10315-1.jpg 874w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/10315-1-464x464.jpg 464w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/10315-1-220x220.jpg 220w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/10315-1-768x768.jpg 768w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/10315-1-300x300.jpg 300w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/10315-1-600x600.jpg 600w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/10315-1-100x100.jpg 100w",
      "sizes": "(max-width: 874px) 100vw, 874px",
      "name": "10315",
      "alt": ""
    },
    "review_count": 0,
    "permalink": "https://beta.stilles.rs/product-category/sudopere/"
  }
]
export type Okovi = typeof okovi
export const plocasti = [
  {
    "id": 67,
    "count": 36,
    "description": "ABS trake nisu samo estetski detalj, već i praktično rešenje za zaštitu ivica vašeg nameštaja.",
    "name": "ABS 3D kant trake",
    "slug": "abs-3d-kant-trake",
    "parent": 62,
    "acf": {
      "card": false,
      "content": "<p>Osim što će vaš nameštaj izgledati besprekorno, ABS trake će ga učiniti otpornijim na oštećenja i produžiti njegov vek trajanja. U našem asortimanu ćete pronaći trake vrhunskog kvaliteta koje će zadovoljiti i najviše standarde.</p>\n"
    },
    "subcategories": []
  },
  {
    "id": 69,
    "count": 1,
    "description": "",
    "name": "ABS trake 0.45 mm",
    "slug": "abs-trake-0-45-mm",
    "parent": 62,
    "acf": {
      "card": false,
      "content": ""
    },
    "subcategories": []
  },
  {
    "id": 71,
    "count": 25,
    "description": "",
    "name": "ABS trake 1mm",
    "slug": "abs-trake-1mm",
    "parent": 62,
    "acf": {
      "card": "https://beta.stilles.rs/cms/wp-content/uploads/2024/08/1.jpg",
      "content": ""
    },
    "subcategories": []
  },
  {
    "id": 70,
    "count": 137,
    "description": "",
    "name": "ABS trake 2mm",
    "slug": "abs-trake-2mm",
    "parent": 62,
    "acf": {
      "card": false,
      "content": ""
    },
    "subcategories": []
  },
  {
    "id": 65,
    "count": 4,
    "description": "description here",
    "name": "Boje",
    "slug": "boje",
    "parent": 55,
    "acf": {
      "card": "https://beta.stilles.rs/cms/wp-content/uploads/2024/06/27121.jpg",
      "content": "<p>sdsds</p>\n"
    },
    "subcategories": []
  },
  {
    "id": 150,
    "count": 49,
    "description": "The description is not prominent by default; however, some themes may show it.",
    "name": "Dekodur",
    "slug": "dekodur",
    "parent": 58,
    "acf": {
      "card": false,
      "content": "<p>Spoj vrhunske nemačke tehnologije, dugogodišnjeg iskustva i svetski poznatih dizajnera čini DEKODUR kompaniju jedinstvenom (neprikosnovenom) i autentičnom u pogledu kvalilteta, dizajna i ideja u svetu.<br />\nVeliki izbor HPL laminata nudi neograničene mogućnosti za kreativno i moderno stvaranje i menjanje (životnog) prostora.<br />\nU ponudi DEKODUR-a se nalaze sofisticirani ultraplast laminati kao:<br />\n– melaminski laminati<br />\n– aluminijumski laminati<br />\n– laminati nerđajućeg čelika<br />\n– mesing laminati<br />\n– bakar laminati u raznim dezenima.<br />\nSa bogatom tradicijom DEKODUR ostaje veran svojoj viziji, i konstantno nudi nove dezene u kombinacijama različitih materijala i struktura.<br />\nU Stilles-u, na lageru, mozete naći preko 50 dezena spremnih za isporuku.<br />\nPosebna pogodnost koju Stilles nudi svojim kupcima jeste da vrši uslužno lepljenje (presovanje) željenog HPL laminata na različite materijale (medijapan, iverica, šper ploča) kao i uslužno sečenje.<br />\nU Stilles-u, takođe možete u dogovoru sa našim dizajnerima osmišljavati različita idejna rešenja u vezi životnog prostora ili dobiti korisne savete.</p>\n"
    },
    "subcategories": []
  },
  {
    "id": 64,
    "count": 12,
    "description": "",
    "name": "Drvo",
    "slug": "drvo",
    "parent": 55,
    "acf": {
      "card": "https://beta.stilles.rs/cms/wp-content/uploads/2024/06/34032.jpg",
      "content": ""
    },
    "subcategories": []
  },
  {
    "id": 54,
    "count": 13,
    "description": "Kastamonu EvoGloss dekorativne ploče",
    "name": "Evo Gloss",
    "slug": "evo-gloss",
    "parent": 0,
    "acf": {
      "card": "https://beta.stilles.rs/cms/wp-content/uploads/2024/06/evo-gloss.jpg",
      "content": "<p>Evo Gloss trend dekori će vas inspirisati originalnim izborom boja,<br />\nimpresivnim stilom i lepotom jednostavnosti</p>\n"
    },
    "subcategories": []
  },
  {
    "id": 66,
    "count": 4,
    "description": "",
    "name": "Fantazije",
    "slug": "fantazije",
    "parent": 55,
    "acf": {
      "card": "https://beta.stilles.rs/cms/wp-content/uploads/2024/08/fantazija.jpg",
      "content": ""
    },
    "subcategories": []
  },
  {
    "id": 57,
    "count": 13,
    "description": "HPL frontovi u visokom sjaju su dostupni u širokom spektru boja vrhunskog kvaliteta",
    "name": "HPL frontovi",
    "slug": "hpl-frontovi",
    "parent": 0,
    "acf": {
      "card": "https://beta.stilles.rs/cms/wp-content/uploads/2024/08/evo-gloss-cover.jpg",
      "content": "<p>Izrađuju se na E1 iverici presvlačeći je dvostrano HPL antiscratch laminatom 1 mm debljine u visokom sjaju.<br />\nFrontovi se najčešće koriste za kuhinjske elemente i plakare.<br />\nPokazali su se kao idealna zamena za farbani medijapan, uz veliku prednost zbog svog visokog sjaja i otpornosti na habanje.</p>\n"
    },
    "subcategories": []
  },
  {
    "id": 58,
    "count": 49,
    "description": "The description is not prominent by default; however, some themes may show it.",
    "name": "HPL laminati",
    "slug": "hpl-laminati",
    "parent": 0,
    "acf": {
      "card": "https://beta.stilles.rs/cms/wp-content/uploads/2024/08/Abet_Laminati_02.jpg",
      "content": "<p>HPL laminati nude neograničenu mogućnost primene, ne samo u izradi nameštaja već i dekoraciji Vašeg enterijera.</p>\n<p>STILLES Vam nudi mogućnost da:</p>\n<ol>\n<li>izaberete željeni dezen laminata ( preko 200 dezena )</li>\n<li>izaberete materijal ( medijapan, sirova iverica ) na koji će biti uslužno presovan HPL laminat koji ste odabrali</li>\n<li>gotovu ploču uslužno sečemo, kantujemo i oblikujemo po Vašem zahtevu</li>\n</ol>\n"
    },
    "subcategories": [
      {
        "term_id": 150,
        "name": "Dekodur",
        "slug": "dekodur",
        "term_group": 0,
        "term_taxonomy_id": 150,
        "taxonomy": "kategorije",
        "description": "The description is not prominent by default; however, some themes may show it.",
        "parent": 58,
        "count": 49,
        "filter": "raw",
        "acf": {
          "card": false
        },
        "content": "<p>Spoj vrhunske nemačke tehnologije, dugogodišnjeg iskustva i svetski poznatih dizajnera čini DEKODUR kompaniju jedinstvenom (neprikosnovenom) i autentičnom u pogledu kvalilteta, dizajna i ideja u svetu.<br />\nVeliki izbor HPL laminata nudi neograničene mogućnosti za kreativno i moderno stvaranje i menjanje (životnog) prostora.<br />\nU ponudi DEKODUR-a se nalaze sofisticirani ultraplast laminati kao:<br />\n– melaminski laminati<br />\n– aluminijumski laminati<br />\n– laminati nerđajućeg čelika<br />\n– mesing laminati<br />\n– bakar laminati u raznim dezenima.<br />\nSa bogatom tradicijom DEKODUR ostaje veran svojoj viziji, i konstantno nudi nove dezene u kombinacijama različitih materijala i struktura.<br />\nU Stilles-u, na lageru, mozete naći preko 50 dezena spremnih za isporuku.<br />\nPosebna pogodnost koju Stilles nudi svojim kupcima jeste da vrši uslužno lepljenje (presovanje) željenog HPL laminata na različite materijale (medijapan, iverica, šper ploča) kao i uslužno sečenje.<br />\nU Stilles-u, takođe možete u dogovoru sa našim dizajnerima osmišljavati različita idejna rešenja u vezi životnog prostora ili dobiti korisne savete.</p>\n"
      },
      {
        "term_id": 151,
        "name": "HPL/CPL laminati",
        "slug": "hpl-cpl-laminati",
        "term_group": 0,
        "term_taxonomy_id": 151,
        "taxonomy": "kategorije",
        "description": "",
        "parent": 58,
        "count": 0,
        "filter": "raw",
        "acf": {
          "card": false
        },
        "content": ""
      }
    ]
  },
  {
    "id": 151,
    "count": 0,
    "description": "",
    "name": "HPL/CPL laminati",
    "slug": "hpl-cpl-laminati",
    "parent": 58,
    "acf": {
      "card": false,
      "content": ""
    },
    "subcategories": []
  },
  {
    "id": 62,
    "count": 247,
    "description": "Description",
    "name": "Kant trake",
    "slug": "kant-trake",
    "parent": 0,
    "acf": {
      "card": "https://beta.stilles.rs/cms/wp-content/uploads/2024/08/featured-dollken.jpg",
      "content": "<p>Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.</p>\n"
    },
    "subcategories": [
      {
        "term_id": 67,
        "name": "ABS 3D kant trake",
        "slug": "abs-3d-kant-trake",
        "term_group": 0,
        "term_taxonomy_id": 67,
        "taxonomy": "kategorije",
        "description": "ABS trake nisu samo estetski detalj, već i praktično rešenje za zaštitu ivica vašeg nameštaja.",
        "parent": 62,
        "count": 36,
        "filter": "raw",
        "acf": {
          "card": false
        },
        "content": "<p>Osim što će vaš nameštaj izgledati besprekorno, ABS trake će ga učiniti otpornijim na oštećenja i produžiti njegov vek trajanja. U našem asortimanu ćete pronaći trake vrhunskog kvaliteta koje će zadovoljiti i najviše standarde.</p>\n"
      },
      {
        "term_id": 69,
        "name": "ABS trake 0.45 mm",
        "slug": "abs-trake-0-45-mm",
        "term_group": 0,
        "term_taxonomy_id": 69,
        "taxonomy": "kategorije",
        "description": "",
        "parent": 62,
        "count": 1,
        "filter": "raw",
        "acf": {
          "card": false
        },
        "content": ""
      },
      {
        "term_id": 71,
        "name": "ABS trake 1mm",
        "slug": "abs-trake-1mm",
        "term_group": 0,
        "term_taxonomy_id": 71,
        "taxonomy": "kategorije",
        "description": "",
        "parent": 62,
        "count": 25,
        "filter": "raw",
        "acf": {
          "card": "https://beta.stilles.rs/cms/wp-content/uploads/2024/08/1.jpg"
        },
        "content": ""
      },
      {
        "term_id": 70,
        "name": "ABS trake 2mm",
        "slug": "abs-trake-2mm",
        "term_group": 0,
        "term_taxonomy_id": 70,
        "taxonomy": "kategorije",
        "description": "",
        "parent": 62,
        "count": 137,
        "filter": "raw",
        "acf": {
          "card": false
        },
        "content": ""
      },
      {
        "term_id": 68,
        "name": "Melaminska",
        "slug": "melaminska",
        "term_group": 0,
        "term_taxonomy_id": 68,
        "taxonomy": "kategorije",
        "description": "",
        "parent": 62,
        "count": 46,
        "filter": "raw",
        "acf": {
          "card": false
        },
        "content": ""
      }
    ]
  },
  {
    "id": 56,
    "count": 89,
    "description": "Stilles nameštaj je proizvođač više od 100 dezena kuhinjskih radnih ploča i pult ploča.",
    "name": "Kuhinjske radne ploče",
    "slug": "kuhinjske-radne-ploce",
    "parent": 0,
    "acf": {
      "card": "https://beta.stilles.rs/cms/wp-content/uploads/2024/08/radne_ploce.jpg",
      "content": "<p>Kuhinjske radne ploče izrađujemo na E1 iverici presvlačeći je mašinski pod pritiskom sa HPL laminatima debljine:</p>\n<p>Visoki sjaj – 0.8 mm<br />\nMat – 0.6 mm<br />\nKvarc i aluminijum – 0.6 mm<br />\nSve neophodne sirovine za proizvodnju nabavljamo isključivo iz inostranih izvora (uglavnom Italija).</p>\n<p>Karakteristike kuhinjskih radnih ploča koje izrađujemo:</p>\n<p>otporne su na grebanje, toplotu i mrlje<br />\nvodootporne ( uz prethodno adekvatnu montažu i zaštitu koju zahtevaju )<br />\njednostavne za obradu<br />\nlako se peru i čiste<br />\nPult ploče izrađujemo na E1 iverici presvlačeći je mašinski pod pritiskom sa HPL laminatima.</p>\n<p>Uglavnom se koriste za trpezarijske i kancelarijske stolove ili kao šank ploče.</p>\n<p>Izrada i prodaja pult ploča proširenih dimenzija</p>\n<p>Potpuna novina na našem tržištu i u našem proizvodnom programu su pult ploče koje izrađujemo u proširenim dimenzijama od standardnih dimenzija kuhinjskih radnih ploča.</p>\n<p>Maksimalna širina kuhinjskih pultova iznosi 1.150mm.<br />\nProizvode se i prodaju u dužinama od 4.2m i pola od toga – 2.1m, dok je debljina standardnih 38mm.</p>\n"
    },
    "subcategories": [
      {
        "term_id": 88,
        "name": "Mat",
        "slug": "mat",
        "term_group": 0,
        "term_taxonomy_id": 88,
        "taxonomy": "kategorije",
        "description": "U zavisnosti od Vaših potreba, vršimo i usluge sečenje kuhinjskih radnih ploča mat boje kao i kantovanje.",
        "parent": 56,
        "count": 74,
        "filter": "raw",
        "acf": {
          "card": "https://beta.stilles.rs/cms/wp-content/uploads/2024/06/493-961-1.jpg"
        },
        "content": ""
      },
      {
        "term_id": 92,
        "name": "Nova kolekcija",
        "slug": "nova-kolekcija",
        "term_group": 0,
        "term_taxonomy_id": 92,
        "taxonomy": "kategorije",
        "description": "",
        "parent": 56,
        "count": 9,
        "filter": "raw",
        "acf": {
          "card": "https://beta.stilles.rs/cms/wp-content/uploads/2024/08/nova-kolekcija.jpeg"
        },
        "content": "<p>Potpuno nova kolekcija <a href=\"/plocasti-materijali/kuhinjske-radne-ploce\">kuhinjskih radnih ploča</a></p>\n<p>U ponudi imamo dodatno novih 26 dezena u <a href=\"/plocasti-materijali/kuhinjske-radne-ploce/mat\">matu</a> i <a href=\"/plocasti-materijali/kuhinjske-radne-ploce/sjaj\">sjaju</a></p>\n"
      },
      {
        "term_id": 90,
        "name": "Pult ploče",
        "slug": "pult-ploce",
        "term_group": 0,
        "term_taxonomy_id": 90,
        "taxonomy": "kategorije",
        "description": "Potpuna novina na našem tržištu i u našem proizvodnom programu.",
        "parent": 56,
        "count": 0,
        "filter": "raw",
        "acf": {
          "card": "https://beta.stilles.rs/cms/wp-content/uploads/2024/06/SL-o-2v2v2.jpg"
        },
        "content": "<p>Potpuna novina na našem tržištu i u našem proizvodnom programu:</p>\n<p>Pult ploče – u proširenim dimenzijama.<br />\nPultovi su radne ploče povećanih dimenzija. Maksimalna širina kuhinjskih pultova je 1.150mm. Proizvode se i prodaju u dužinama od 4.2m i pola od toga – 2.1m, dok je debljina standardnih 38mm.</p>\n<p>U stalnoj ponudi imamo devet dekora, sa mogućnošću proizvodnje u dezenima radnih ploča koje inače imamo u svojoj ponudi.<br />\nTakođe, imamo laminat kant traku za svaki dekor.</p>\n<p>Karakteristike:</p>\n<ul>\n<li>otporne na grebanje, toplotu i mrlje</li>\n<li>vodootporne ( uz prethodno adekvatnu montažu i zaštitu koju zahtevaju )</li>\n<li>jednostavne za obradu</li>\n<li>lako se peru i čiste</li>\n</ul>\n<p>Idealni su za šankove, trpezarijske stolove, kao i za kancelarijske stolove.<br />\nMožete objediniti izgled Vaše kuhinje sa kuhinjskom radnom pločom, zidnom oblogom i pult pločom sve u istom dekoru.<br />\nKarakteristike važe za sve isto, da su vodootporne, lako se održavaju, otporne na grebanje, toplotu i mrlje.<br />\nSve dezene možete pogledati pod kategorijom kuhinjske radne ploče.</p>\n"
      },
      {
        "term_id": 89,
        "name": "Sjaj",
        "slug": "sjaj",
        "term_group": 0,
        "term_taxonomy_id": 89,
        "taxonomy": "kategorije",
        "description": "Vršimo uslugu sečenja i kantovanja kuhinjskih radnih ploča visokog sjaja",
        "parent": 56,
        "count": 6,
        "filter": "raw",
        "acf": {
          "card": "https://beta.stilles.rs/cms/wp-content/uploads/2024/06/bnla-iskra.jpg"
        },
        "content": "<p>Vršimo uslugu sečenja i kantovanja kuhinjskih radnih ploča visokog sjaja.</p>\n"
      },
      {
        "term_id": 91,
        "name": "Zidne obloge",
        "slug": "zidne-obloge",
        "term_group": 0,
        "term_taxonomy_id": 91,
        "taxonomy": "kategorije",
        "description": "",
        "parent": 56,
        "count": 0,
        "filter": "raw",
        "acf": {
          "card": false
        },
        "content": ""
      }
    ]
  },
  {
    "id": 88,
    "count": 74,
    "description": "U zavisnosti od Vaših potreba, vršimo i usluge sečenje kuhinjskih radnih ploča mat boje kao i kantovanje.",
    "name": "Mat",
    "slug": "mat",
    "parent": 56,
    "acf": {
      "card": "https://beta.stilles.rs/cms/wp-content/uploads/2024/06/493-961-1.jpg",
      "content": ""
    },
    "subcategories": []
  },
  {
    "id": 60,
    "count": 0,
    "description": "",
    "name": "Medijapan i lesonit",
    "slug": "medijapan-i-lesonit",
    "parent": 0,
    "acf": {
      "card": "https://beta.stilles.rs/cms/wp-content/uploads/2024/08/lesonit-cvoer.jpg",
      "content": ""
    },
    "subcategories": []
  },
  {
    "id": 68,
    "count": 46,
    "description": "",
    "name": "Melaminska",
    "slug": "melaminska",
    "parent": 62,
    "acf": {
      "card": false,
      "content": ""
    },
    "subcategories": []
  },
  {
    "id": 92,
    "count": 9,
    "description": "",
    "name": "Nova kolekcija",
    "slug": "nova-kolekcija",
    "parent": 56,
    "acf": {
      "card": "https://beta.stilles.rs/cms/wp-content/uploads/2024/08/nova-kolekcija.jpeg",
      "content": "<p>Potpuno nova kolekcija <a href=\"/plocasti-materijali/kuhinjske-radne-ploce\">kuhinjskih radnih ploča</a></p>\n<p>U ponudi imamo dodatno novih 26 dezena u <a href=\"/plocasti-materijali/kuhinjske-radne-ploce/mat\">matu</a> i <a href=\"/plocasti-materijali/kuhinjske-radne-ploce/sjaj\">sjaju</a></p>\n"
    },
    "subcategories": []
  },
  {
    "id": 63,
    "count": 2,
    "description": "",
    "name": "Ostali materijali",
    "slug": "ostali-materijali",
    "parent": 0,
    "acf": {
      "card": "https://beta.stilles.rs/cms/wp-content/uploads/2024/08/lesonit.jpg",
      "content": "<p>U ponudi još imamo:</p>\n<ul>\n<li>građevinske ploče OSB, Qsb</li>\n<li>sirova iverica</li>\n<li>šper ploče</li>\n<li>furnirane ploče</li>\n<li>vodootporni šper – blažujka</li>\n</ul>\n"
    },
    "subcategories": []
  },
  {
    "id": 59,
    "count": 6,
    "description": "Postforming frontovi se izrađuju od iverice presvučene HPL laminatom, potom zaobljene 2 strane. Tako dobijamo ploče u standardnim širinama: 300, 400, 450 i 600mm.",
    "name": "Postforming frontovi",
    "slug": "postforming-frontovi",
    "parent": 0,
    "acf": {
      "card": "https://beta.stilles.rs/cms/wp-content/uploads/2024/08/MG_0654-pr-Small.jpg",
      "content": "<p>Sve neophodne komponente za proizvodnju postforminga su visokog kvaliteta, i nabavljaju se isključivo iz inostranih izvora. Savremena tehnologija, pored visokog kvaliteta sastavnih komponenti, garantuje kvalitet postforming ploča, koje mogu da odgovore zahtevima savremenog tržišta.</p>\n<p>Sopstvena proizvodnja STILLES-a omogućava konstantno i kontinuirano snadbevanje, zahvaljujući velikom lageru sirovina.</p>\n<p>Postforming ploče su otporne na habanje, visoku temperaturu i vlagu (uz prethodno adekvatnu montažu i zaštitu koju zahtevaju).</p>\n<p>Dužina tabli je 3050 mm ( u maloprodaji table sečemo na tačnu meru ).</p>\n"
    },
    "subcategories": []
  },
  {
    "id": 90,
    "count": 0,
    "description": "Potpuna novina na našem tržištu i u našem proizvodnom programu.",
    "name": "Pult ploče",
    "slug": "pult-ploce",
    "parent": 56,
    "acf": {
      "card": "https://beta.stilles.rs/cms/wp-content/uploads/2024/06/SL-o-2v2v2.jpg",
      "content": "<p>Potpuna novina na našem tržištu i u našem proizvodnom programu:</p>\n<p>Pult ploče – u proširenim dimenzijama.<br />\nPultovi su radne ploče povećanih dimenzija. Maksimalna širina kuhinjskih pultova je 1.150mm. Proizvode se i prodaju u dužinama od 4.2m i pola od toga – 2.1m, dok je debljina standardnih 38mm.</p>\n<p>U stalnoj ponudi imamo devet dekora, sa mogućnošću proizvodnje u dezenima radnih ploča koje inače imamo u svojoj ponudi.<br />\nTakođe, imamo laminat kant traku za svaki dekor.</p>\n<p>Karakteristike:</p>\n<ul>\n<li>otporne na grebanje, toplotu i mrlje</li>\n<li>vodootporne ( uz prethodno adekvatnu montažu i zaštitu koju zahtevaju )</li>\n<li>jednostavne za obradu</li>\n<li>lako se peru i čiste</li>\n</ul>\n<p>Idealni su za šankove, trpezarijske stolove, kao i za kancelarijske stolove.<br />\nMožete objediniti izgled Vaše kuhinje sa kuhinjskom radnom pločom, zidnom oblogom i pult pločom sve u istom dekoru.<br />\nKarakteristike važe za sve isto, da su vodootporne, lako se održavaju, otporne na grebanje, toplotu i mrlje.<br />\nSve dezene možete pogledati pod kategorijom kuhinjske radne ploče.</p>\n"
    },
    "subcategories": []
  },
  {
    "id": 89,
    "count": 6,
    "description": "Vršimo uslugu sečenja i kantovanja kuhinjskih radnih ploča visokog sjaja",
    "name": "Sjaj",
    "slug": "sjaj",
    "parent": 56,
    "acf": {
      "card": "https://beta.stilles.rs/cms/wp-content/uploads/2024/06/bnla-iskra.jpg",
      "content": "<p>Vršimo uslugu sečenja i kantovanja kuhinjskih radnih ploča visokog sjaja.</p>\n"
    },
    "subcategories": []
  },
  {
    "id": 55,
    "count": 20,
    "description": "Oplemenjena iverica",
    "name": "Univer ploče",
    "slug": "univer",
    "parent": 0,
    "acf": {
      "card": "https://beta.stilles.rs/cms/wp-content/uploads/2024/08/univer-cover-photo.jpg",
      "content": "<p>Univer ploče pružaju veliku mogućnost oblikovanja po želji i odličnu izdržljivost.<br />\nPrimenljiv kako u kućnim enterijerima, tako i za široke komercijalne svrhe, kao što su: hoteli, zdravstvene ustanove, banke, saloni…<br />\nVršimo uslužno sečenje i kantovanje univer ploča.<br />\nTakođe imamo odgovarajuće kant trake za sve dezene univera.<br />\nDirektni smo zastupnici i distributeri Kastamonu grupacije.</p>\n"
    },
    "subcategories": [
      {
        "term_id": 65,
        "name": "Boje",
        "slug": "boje",
        "term_group": 0,
        "term_taxonomy_id": 65,
        "taxonomy": "kategorije",
        "description": "description here",
        "parent": 55,
        "count": 4,
        "filter": "raw",
        "acf": {
          "card": "https://beta.stilles.rs/cms/wp-content/uploads/2024/06/27121.jpg"
        },
        "content": "<p>sdsds</p>\n"
      },
      {
        "term_id": 64,
        "name": "Drvo",
        "slug": "drvo",
        "term_group": 0,
        "term_taxonomy_id": 64,
        "taxonomy": "kategorije",
        "description": "",
        "parent": 55,
        "count": 12,
        "filter": "raw",
        "acf": {
          "card": "https://beta.stilles.rs/cms/wp-content/uploads/2024/06/34032.jpg"
        },
        "content": ""
      },
      {
        "term_id": 66,
        "name": "Fantazije",
        "slug": "fantazije",
        "term_group": 0,
        "term_taxonomy_id": 66,
        "taxonomy": "kategorije",
        "description": "",
        "parent": 55,
        "count": 4,
        "filter": "raw",
        "acf": {
          "card": "https://beta.stilles.rs/cms/wp-content/uploads/2024/08/fantazija.jpg"
        },
        "content": ""
      }
    ]
  },
  {
    "id": 91,
    "count": 0,
    "description": "",
    "name": "Zidne obloge",
    "slug": "zidne-obloge",
    "parent": 56,
    "acf": {
      "card": false,
      "content": ""
    },
    "subcategories": []
  }
]
export type Plocasti = typeof plocasti
export const okoviParent = [
  {
    "id": 76,
    "name": "Bravice za nameštaj",
    "slug": "bravice-za-namestaj",
    "description": "Pellentesque faucibus maximus tellus nec porttitor. Quisque cursus dignissim quam sit amet pharetra. Sed non sapien quis libero placerat efficitur. Praesent sit amet tincidunt dui.",
    "parent": 0,
    "count": 19,
    "image": {
      "id": 343,
      "src": "https://beta.stilles.rs/cms/wp-content/uploads/2024/06/bravice-za-namestaj.jpg",
      "thumbnail": "https://beta.stilles.rs/cms/wp-content/uploads/2024/06/bravice-za-namestaj-300x300.jpg",
      "srcset": "https://beta.stilles.rs/cms/wp-content/uploads/2024/06/bravice-za-namestaj.jpg 700w, https://beta.stilles.rs/cms/wp-content/uploads/2024/06/bravice-za-namestaj-464x325.jpg 464w, https://beta.stilles.rs/cms/wp-content/uploads/2024/06/bravice-za-namestaj-220x154.jpg 220w, https://beta.stilles.rs/cms/wp-content/uploads/2024/06/bravice-za-namestaj-600x420.jpg 600w",
      "sizes": "(max-width: 700px) 100vw, 700px",
      "name": "bravice-za-namestaj",
      "alt": ""
    },
    "review_count": 0,
    "permalink": "https://beta.stilles.rs/product-category/bravice-za-namestaj/"
  },
  {
    "id": 83,
    "name": "Klizni mehanizmi",
    "slug": "klizni-mehanizmi",
    "description": "",
    "parent": 0,
    "count": 218,
    "image": {
      "id": 3286,
      "src": "https://beta.stilles.rs/cms/wp-content/uploads/2024/07/klizni-mehanizmi.jpg",
      "thumbnail": "https://beta.stilles.rs/cms/wp-content/uploads/2024/07/klizni-mehanizmi-300x300.jpg",
      "srcset": "https://beta.stilles.rs/cms/wp-content/uploads/2024/07/klizni-mehanizmi.jpg 512w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/klizni-mehanizmi-464x464.jpg 464w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/klizni-mehanizmi-220x220.jpg 220w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/klizni-mehanizmi-300x300.jpg 300w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/klizni-mehanizmi-100x100.jpg 100w",
      "sizes": "(max-width: 512px) 100vw, 512px",
      "name": "klizni-mehanizmi",
      "alt": ""
    },
    "review_count": 0,
    "permalink": "https://beta.stilles.rs/product-category/klizni-mehanizmi/"
  },
  {
    "id": 79,
    "name": "Lajsne za nameštaj",
    "slug": "lajsne-za-namestaj",
    "description": "",
    "parent": 0,
    "count": 244,
    "image": {
      "id": 3302,
      "src": "https://beta.stilles.rs/cms/wp-content/uploads/2024/07/lajsna.jpg",
      "thumbnail": "https://beta.stilles.rs/cms/wp-content/uploads/2024/07/lajsna-300x300.jpg",
      "srcset": "https://beta.stilles.rs/cms/wp-content/uploads/2024/07/lajsna.jpg 512w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/lajsna-464x464.jpg 464w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/lajsna-220x220.jpg 220w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/lajsna-300x300.jpg 300w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/lajsna-100x100.jpg 100w",
      "sizes": "(max-width: 512px) 100vw, 512px",
      "name": "lajsna",
      "alt": ""
    },
    "review_count": 0,
    "permalink": "https://beta.stilles.rs/product-category/lajsne-za-namestaj/"
  },
  {
    "id": 115,
    "name": "Lepkovi",
    "slug": "lepkovi",
    "description": "",
    "parent": 0,
    "count": 55,
    "image": {
      "id": 3285,
      "src": "https://beta.stilles.rs/cms/wp-content/uploads/2024/07/lepak-e1721403403178.jpeg",
      "thumbnail": "https://beta.stilles.rs/cms/wp-content/uploads/2024/07/lepak-300x300.jpeg",
      "srcset": "",
      "sizes": "(max-width: 600px) 100vw, 600px",
      "name": "lepak",
      "alt": ""
    },
    "review_count": 0,
    "permalink": "https://beta.stilles.rs/product-category/lepkovi/"
  },
  {
    "id": 40,
    "name": "Nekategorizovano",
    "slug": "nekategorizovano",
    "description": "Okov za nameštaj koji nije razvrstan u druge,  već postojeće kategorije",
    "parent": 0,
    "count": 1926,
    "image": {
      "id": 3301,
      "src": "https://beta.stilles.rs/cms/wp-content/uploads/2024/07/nekategorizovano.jpg",
      "thumbnail": "https://beta.stilles.rs/cms/wp-content/uploads/2024/07/nekategorizovano-300x300.jpg",
      "srcset": "https://beta.stilles.rs/cms/wp-content/uploads/2024/07/nekategorizovano.jpg 512w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/nekategorizovano-464x464.jpg 464w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/nekategorizovano-220x220.jpg 220w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/nekategorizovano-300x300.jpg 300w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/nekategorizovano-100x100.jpg 100w",
      "sizes": "(max-width: 512px) 100vw, 512px",
      "name": "nekategorizovano",
      "alt": ""
    },
    "review_count": 0,
    "permalink": "https://beta.stilles.rs/product-category/nekategorizovano/"
  },
  {
    "id": 74,
    "name": "Nogice za nameštaj",
    "slug": "nogice-za-namestaj",
    "description": "Nogice za nameštaj su mali dodaci koji se pričvršćuju za dno stolova, stolica, fotelja i drugih komada nameštaja kako bi se podigao, omogućilo provetravanje ispod nameštaja, ili zaštitio pod od ogrebotina.",
    "parent": 0,
    "count": 197,
    "image": {
      "id": 3287,
      "src": "https://beta.stilles.rs/cms/wp-content/uploads/2024/07/3416029.jpg.webp",
      "thumbnail": "https://beta.stilles.rs/cms/wp-content/uploads/2024/07/3416029.jpg-300x300.webp",
      "srcset": "https://beta.stilles.rs/cms/wp-content/uploads/2024/07/3416029.jpg.webp 500w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/3416029.jpg-464x464.webp 464w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/3416029.jpg-220x220.webp 220w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/3416029.jpg-300x300.webp 300w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/3416029.jpg-100x100.webp 100w",
      "sizes": "(max-width: 500px) 100vw, 500px",
      "name": "3416029.jpg",
      "alt": ""
    },
    "review_count": 0,
    "permalink": "https://beta.stilles.rs/product-category/nogice-za-namestaj/"
  },
  {
    "id": 77,
    "name": "Nosači nameštaja",
    "slug": "nosaci-namestaja",
    "description": "",
    "parent": 0,
    "count": 192,
    "image": {
      "id": 3298,
      "src": "https://beta.stilles.rs/cms/wp-content/uploads/2024/07/nosaci.jpg",
      "thumbnail": "https://beta.stilles.rs/cms/wp-content/uploads/2024/07/nosaci-300x300.jpg",
      "srcset": "https://beta.stilles.rs/cms/wp-content/uploads/2024/07/nosaci.jpg 512w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/nosaci-464x464.jpg 464w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/nosaci-220x220.jpg 220w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/nosaci-300x300.jpg 300w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/nosaci-100x100.jpg 100w",
      "sizes": "(max-width: 512px) 100vw, 512px",
      "name": "nosaci",
      "alt": ""
    },
    "review_count": 0,
    "permalink": "https://beta.stilles.rs/product-category/nosaci-namestaja/"
  },
  {
    "id": 113,
    "name": "Oklagije",
    "slug": "oklagije",
    "description": "",
    "parent": 0,
    "count": 9,
    "image": {
      "id": 3300,
      "src": "https://beta.stilles.rs/cms/wp-content/uploads/2024/07/oklagija.jpg",
      "thumbnail": "https://beta.stilles.rs/cms/wp-content/uploads/2024/07/oklagija-300x300.jpg",
      "srcset": "https://beta.stilles.rs/cms/wp-content/uploads/2024/07/oklagija.jpg 512w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/oklagija-464x464.jpg 464w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/oklagija-220x220.jpg 220w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/oklagija-300x300.jpg 300w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/oklagija-100x100.jpg 100w",
      "sizes": "(max-width: 512px) 100vw, 512px",
      "name": "oklagija",
      "alt": ""
    },
    "review_count": 0,
    "permalink": "https://beta.stilles.rs/product-category/oklagije/"
  },
  {
    "id": 112,
    "name": "Podizni mehanizmi",
    "slug": "podizni-mehanizmi",
    "description": "",
    "parent": 0,
    "count": 5,
    "image": {
      "id": 3297,
      "src": "https://beta.stilles.rs/cms/wp-content/uploads/2024/07/podizaci.jpg",
      "thumbnail": "https://beta.stilles.rs/cms/wp-content/uploads/2024/07/podizaci-300x300.jpg",
      "srcset": "https://beta.stilles.rs/cms/wp-content/uploads/2024/07/podizaci.jpg 512w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/podizaci-464x464.jpg 464w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/podizaci-220x220.jpg 220w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/podizaci-300x300.jpg 300w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/podizaci-100x100.jpg 100w",
      "sizes": "(max-width: 512px) 100vw, 512px",
      "name": "podizaci",
      "alt": ""
    },
    "review_count": 0,
    "permalink": "https://beta.stilles.rs/product-category/podizni-mehanizmi/"
  },
  {
    "id": 116,
    "name": "Rozetne",
    "slug": "rozetne",
    "description": "",
    "parent": 0,
    "count": 6,
    "image": {
      "id": 3295,
      "src": "https://beta.stilles.rs/cms/wp-content/uploads/2024/07/rozetna.jpg",
      "thumbnail": "https://beta.stilles.rs/cms/wp-content/uploads/2024/07/rozetna-300x300.jpg",
      "srcset": "https://beta.stilles.rs/cms/wp-content/uploads/2024/07/rozetna.jpg 512w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/rozetna-464x464.jpg 464w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/rozetna-220x220.jpg 220w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/rozetna-300x300.jpg 300w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/rozetna-100x100.jpg 100w",
      "sizes": "(max-width: 512px) 100vw, 512px",
      "name": "rozetna",
      "alt": ""
    },
    "review_count": 0,
    "permalink": "https://beta.stilles.rs/product-category/rozetne/"
  },
  {
    "id": 84,
    "name": "Sistemi za spajanje nameštaja",
    "slug": "sistemi-za-spajanje-namestaja",
    "description": "",
    "parent": 0,
    "count": 12,
    "image": {
      "id": 3292,
      "src": "https://beta.stilles.rs/cms/wp-content/uploads/2024/07/drveni-tipl.jpg",
      "thumbnail": "https://beta.stilles.rs/cms/wp-content/uploads/2024/07/drveni-tipl-300x300.jpg",
      "srcset": "https://beta.stilles.rs/cms/wp-content/uploads/2024/07/drveni-tipl.jpg 600w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/drveni-tipl-464x464.jpg 464w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/drveni-tipl-220x220.jpg 220w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/drveni-tipl-300x300.jpg 300w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/drveni-tipl-100x100.jpg 100w",
      "sizes": "(max-width: 600px) 100vw, 600px",
      "name": "drveni-tipl",
      "alt": ""
    },
    "review_count": 0,
    "permalink": "https://beta.stilles.rs/product-category/sistemi-za-spajanje-namestaja/"
  },
  {
    "id": 80,
    "name": "Sudopere",
    "slug": "sudopere",
    "description": "",
    "parent": 0,
    "count": 217,
    "image": {
      "id": 3277,
      "src": "https://beta.stilles.rs/cms/wp-content/uploads/2024/07/10315-1.jpg",
      "thumbnail": "https://beta.stilles.rs/cms/wp-content/uploads/2024/07/10315-1-300x300.jpg",
      "srcset": "https://beta.stilles.rs/cms/wp-content/uploads/2024/07/10315-1.jpg 874w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/10315-1-464x464.jpg 464w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/10315-1-220x220.jpg 220w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/10315-1-768x768.jpg 768w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/10315-1-300x300.jpg 300w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/10315-1-600x600.jpg 600w, https://beta.stilles.rs/cms/wp-content/uploads/2024/07/10315-1-100x100.jpg 100w",
      "sizes": "(max-width: 874px) 100vw, 874px",
      "name": "10315",
      "alt": ""
    },
    "review_count": 0,
    "permalink": "https://beta.stilles.rs/product-category/sudopere/"
  }
]
export type OkoviParent = typeof okoviParent
export const plocastiParent = [
  {
    "id": 54,
    "count": 13,
    "description": "Kastamonu EvoGloss dekorativne ploče",
    "name": "Evo Gloss",
    "slug": "evo-gloss",
    "parent": 0,
    "acf": {
      "card": "https://beta.stilles.rs/cms/wp-content/uploads/2024/06/evo-gloss.jpg",
      "content": "<p>Evo Gloss trend dekori će vas inspirisati originalnim izborom boja,<br />\nimpresivnim stilom i lepotom jednostavnosti</p>\n"
    },
    "subcategories": []
  },
  {
    "id": 57,
    "count": 13,
    "description": "HPL frontovi u visokom sjaju su dostupni u širokom spektru boja vrhunskog kvaliteta",
    "name": "HPL frontovi",
    "slug": "hpl-frontovi",
    "parent": 0,
    "acf": {
      "card": "https://beta.stilles.rs/cms/wp-content/uploads/2024/08/evo-gloss-cover.jpg",
      "content": "<p>Izrađuju se na E1 iverici presvlačeći je dvostrano HPL antiscratch laminatom 1 mm debljine u visokom sjaju.<br />\nFrontovi se najčešće koriste za kuhinjske elemente i plakare.<br />\nPokazali su se kao idealna zamena za farbani medijapan, uz veliku prednost zbog svog visokog sjaja i otpornosti na habanje.</p>\n"
    },
    "subcategories": []
  },
  {
    "id": 58,
    "count": 49,
    "description": "The description is not prominent by default; however, some themes may show it.",
    "name": "HPL laminati",
    "slug": "hpl-laminati",
    "parent": 0,
    "acf": {
      "card": "https://beta.stilles.rs/cms/wp-content/uploads/2024/08/Abet_Laminati_02.jpg",
      "content": "<p>HPL laminati nude neograničenu mogućnost primene, ne samo u izradi nameštaja već i dekoraciji Vašeg enterijera.</p>\n<p>STILLES Vam nudi mogućnost da:</p>\n<ol>\n<li>izaberete željeni dezen laminata ( preko 200 dezena )</li>\n<li>izaberete materijal ( medijapan, sirova iverica ) na koji će biti uslužno presovan HPL laminat koji ste odabrali</li>\n<li>gotovu ploču uslužno sečemo, kantujemo i oblikujemo po Vašem zahtevu</li>\n</ol>\n"
    },
    "subcategories": [
      {
        "term_id": 150,
        "name": "Dekodur",
        "slug": "dekodur",
        "term_group": 0,
        "term_taxonomy_id": 150,
        "taxonomy": "kategorije",
        "description": "The description is not prominent by default; however, some themes may show it.",
        "parent": 58,
        "count": 49,
        "filter": "raw",
        "acf": {
          "card": false
        },
        "content": "<p>Spoj vrhunske nemačke tehnologije, dugogodišnjeg iskustva i svetski poznatih dizajnera čini DEKODUR kompaniju jedinstvenom (neprikosnovenom) i autentičnom u pogledu kvalilteta, dizajna i ideja u svetu.<br />\nVeliki izbor HPL laminata nudi neograničene mogućnosti za kreativno i moderno stvaranje i menjanje (životnog) prostora.<br />\nU ponudi DEKODUR-a se nalaze sofisticirani ultraplast laminati kao:<br />\n– melaminski laminati<br />\n– aluminijumski laminati<br />\n– laminati nerđajućeg čelika<br />\n– mesing laminati<br />\n– bakar laminati u raznim dezenima.<br />\nSa bogatom tradicijom DEKODUR ostaje veran svojoj viziji, i konstantno nudi nove dezene u kombinacijama različitih materijala i struktura.<br />\nU Stilles-u, na lageru, mozete naći preko 50 dezena spremnih za isporuku.<br />\nPosebna pogodnost koju Stilles nudi svojim kupcima jeste da vrši uslužno lepljenje (presovanje) željenog HPL laminata na različite materijale (medijapan, iverica, šper ploča) kao i uslužno sečenje.<br />\nU Stilles-u, takođe možete u dogovoru sa našim dizajnerima osmišljavati različita idejna rešenja u vezi životnog prostora ili dobiti korisne savete.</p>\n"
      },
      {
        "term_id": 151,
        "name": "HPL/CPL laminati",
        "slug": "hpl-cpl-laminati",
        "term_group": 0,
        "term_taxonomy_id": 151,
        "taxonomy": "kategorije",
        "description": "",
        "parent": 58,
        "count": 0,
        "filter": "raw",
        "acf": {
          "card": false
        },
        "content": ""
      }
    ]
  },
  {
    "id": 62,
    "count": 247,
    "description": "Description",
    "name": "Kant trake",
    "slug": "kant-trake",
    "parent": 0,
    "acf": {
      "card": "https://beta.stilles.rs/cms/wp-content/uploads/2024/08/featured-dollken.jpg",
      "content": "<p>Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.</p>\n"
    },
    "subcategories": [
      {
        "term_id": 67,
        "name": "ABS 3D kant trake",
        "slug": "abs-3d-kant-trake",
        "term_group": 0,
        "term_taxonomy_id": 67,
        "taxonomy": "kategorije",
        "description": "ABS trake nisu samo estetski detalj, već i praktično rešenje za zaštitu ivica vašeg nameštaja.",
        "parent": 62,
        "count": 36,
        "filter": "raw",
        "acf": {
          "card": false
        },
        "content": "<p>Osim što će vaš nameštaj izgledati besprekorno, ABS trake će ga učiniti otpornijim na oštećenja i produžiti njegov vek trajanja. U našem asortimanu ćete pronaći trake vrhunskog kvaliteta koje će zadovoljiti i najviše standarde.</p>\n"
      },
      {
        "term_id": 69,
        "name": "ABS trake 0.45 mm",
        "slug": "abs-trake-0-45-mm",
        "term_group": 0,
        "term_taxonomy_id": 69,
        "taxonomy": "kategorije",
        "description": "",
        "parent": 62,
        "count": 1,
        "filter": "raw",
        "acf": {
          "card": false
        },
        "content": ""
      },
      {
        "term_id": 71,
        "name": "ABS trake 1mm",
        "slug": "abs-trake-1mm",
        "term_group": 0,
        "term_taxonomy_id": 71,
        "taxonomy": "kategorije",
        "description": "",
        "parent": 62,
        "count": 25,
        "filter": "raw",
        "acf": {
          "card": "https://beta.stilles.rs/cms/wp-content/uploads/2024/08/1.jpg"
        },
        "content": ""
      },
      {
        "term_id": 70,
        "name": "ABS trake 2mm",
        "slug": "abs-trake-2mm",
        "term_group": 0,
        "term_taxonomy_id": 70,
        "taxonomy": "kategorije",
        "description": "",
        "parent": 62,
        "count": 137,
        "filter": "raw",
        "acf": {
          "card": false
        },
        "content": ""
      },
      {
        "term_id": 68,
        "name": "Melaminska",
        "slug": "melaminska",
        "term_group": 0,
        "term_taxonomy_id": 68,
        "taxonomy": "kategorije",
        "description": "",
        "parent": 62,
        "count": 46,
        "filter": "raw",
        "acf": {
          "card": false
        },
        "content": ""
      }
    ]
  },
  {
    "id": 56,
    "count": 89,
    "description": "Stilles nameštaj je proizvođač više od 100 dezena kuhinjskih radnih ploča i pult ploča.",
    "name": "Kuhinjske radne ploče",
    "slug": "kuhinjske-radne-ploce",
    "parent": 0,
    "acf": {
      "card": "https://beta.stilles.rs/cms/wp-content/uploads/2024/08/radne_ploce.jpg",
      "content": "<p>Kuhinjske radne ploče izrađujemo na E1 iverici presvlačeći je mašinski pod pritiskom sa HPL laminatima debljine:</p>\n<p>Visoki sjaj – 0.8 mm<br />\nMat – 0.6 mm<br />\nKvarc i aluminijum – 0.6 mm<br />\nSve neophodne sirovine za proizvodnju nabavljamo isključivo iz inostranih izvora (uglavnom Italija).</p>\n<p>Karakteristike kuhinjskih radnih ploča koje izrađujemo:</p>\n<p>otporne su na grebanje, toplotu i mrlje<br />\nvodootporne ( uz prethodno adekvatnu montažu i zaštitu koju zahtevaju )<br />\njednostavne za obradu<br />\nlako se peru i čiste<br />\nPult ploče izrađujemo na E1 iverici presvlačeći je mašinski pod pritiskom sa HPL laminatima.</p>\n<p>Uglavnom se koriste za trpezarijske i kancelarijske stolove ili kao šank ploče.</p>\n<p>Izrada i prodaja pult ploča proširenih dimenzija</p>\n<p>Potpuna novina na našem tržištu i u našem proizvodnom programu su pult ploče koje izrađujemo u proširenim dimenzijama od standardnih dimenzija kuhinjskih radnih ploča.</p>\n<p>Maksimalna širina kuhinjskih pultova iznosi 1.150mm.<br />\nProizvode se i prodaju u dužinama od 4.2m i pola od toga – 2.1m, dok je debljina standardnih 38mm.</p>\n"
    },
    "subcategories": [
      {
        "term_id": 88,
        "name": "Mat",
        "slug": "mat",
        "term_group": 0,
        "term_taxonomy_id": 88,
        "taxonomy": "kategorije",
        "description": "U zavisnosti od Vaših potreba, vršimo i usluge sečenje kuhinjskih radnih ploča mat boje kao i kantovanje.",
        "parent": 56,
        "count": 74,
        "filter": "raw",
        "acf": {
          "card": "https://beta.stilles.rs/cms/wp-content/uploads/2024/06/493-961-1.jpg"
        },
        "content": ""
      },
      {
        "term_id": 92,
        "name": "Nova kolekcija",
        "slug": "nova-kolekcija",
        "term_group": 0,
        "term_taxonomy_id": 92,
        "taxonomy": "kategorije",
        "description": "",
        "parent": 56,
        "count": 9,
        "filter": "raw",
        "acf": {
          "card": "https://beta.stilles.rs/cms/wp-content/uploads/2024/08/nova-kolekcija.jpeg"
        },
        "content": "<p>Potpuno nova kolekcija <a href=\"/plocasti-materijali/kuhinjske-radne-ploce\">kuhinjskih radnih ploča</a></p>\n<p>U ponudi imamo dodatno novih 26 dezena u <a href=\"/plocasti-materijali/kuhinjske-radne-ploce/mat\">matu</a> i <a href=\"/plocasti-materijali/kuhinjske-radne-ploce/sjaj\">sjaju</a></p>\n"
      },
      {
        "term_id": 90,
        "name": "Pult ploče",
        "slug": "pult-ploce",
        "term_group": 0,
        "term_taxonomy_id": 90,
        "taxonomy": "kategorije",
        "description": "Potpuna novina na našem tržištu i u našem proizvodnom programu.",
        "parent": 56,
        "count": 0,
        "filter": "raw",
        "acf": {
          "card": "https://beta.stilles.rs/cms/wp-content/uploads/2024/06/SL-o-2v2v2.jpg"
        },
        "content": "<p>Potpuna novina na našem tržištu i u našem proizvodnom programu:</p>\n<p>Pult ploče – u proširenim dimenzijama.<br />\nPultovi su radne ploče povećanih dimenzija. Maksimalna širina kuhinjskih pultova je 1.150mm. Proizvode se i prodaju u dužinama od 4.2m i pola od toga – 2.1m, dok je debljina standardnih 38mm.</p>\n<p>U stalnoj ponudi imamo devet dekora, sa mogućnošću proizvodnje u dezenima radnih ploča koje inače imamo u svojoj ponudi.<br />\nTakođe, imamo laminat kant traku za svaki dekor.</p>\n<p>Karakteristike:</p>\n<ul>\n<li>otporne na grebanje, toplotu i mrlje</li>\n<li>vodootporne ( uz prethodno adekvatnu montažu i zaštitu koju zahtevaju )</li>\n<li>jednostavne za obradu</li>\n<li>lako se peru i čiste</li>\n</ul>\n<p>Idealni su za šankove, trpezarijske stolove, kao i za kancelarijske stolove.<br />\nMožete objediniti izgled Vaše kuhinje sa kuhinjskom radnom pločom, zidnom oblogom i pult pločom sve u istom dekoru.<br />\nKarakteristike važe za sve isto, da su vodootporne, lako se održavaju, otporne na grebanje, toplotu i mrlje.<br />\nSve dezene možete pogledati pod kategorijom kuhinjske radne ploče.</p>\n"
      },
      {
        "term_id": 89,
        "name": "Sjaj",
        "slug": "sjaj",
        "term_group": 0,
        "term_taxonomy_id": 89,
        "taxonomy": "kategorije",
        "description": "Vršimo uslugu sečenja i kantovanja kuhinjskih radnih ploča visokog sjaja",
        "parent": 56,
        "count": 6,
        "filter": "raw",
        "acf": {
          "card": "https://beta.stilles.rs/cms/wp-content/uploads/2024/06/bnla-iskra.jpg"
        },
        "content": "<p>Vršimo uslugu sečenja i kantovanja kuhinjskih radnih ploča visokog sjaja.</p>\n"
      },
      {
        "term_id": 91,
        "name": "Zidne obloge",
        "slug": "zidne-obloge",
        "term_group": 0,
        "term_taxonomy_id": 91,
        "taxonomy": "kategorije",
        "description": "",
        "parent": 56,
        "count": 0,
        "filter": "raw",
        "acf": {
          "card": false
        },
        "content": ""
      }
    ]
  },
  {
    "id": 60,
    "count": 0,
    "description": "",
    "name": "Medijapan i lesonit",
    "slug": "medijapan-i-lesonit",
    "parent": 0,
    "acf": {
      "card": "https://beta.stilles.rs/cms/wp-content/uploads/2024/08/lesonit-cvoer.jpg",
      "content": ""
    },
    "subcategories": []
  },
  {
    "id": 63,
    "count": 2,
    "description": "",
    "name": "Ostali materijali",
    "slug": "ostali-materijali",
    "parent": 0,
    "acf": {
      "card": "https://beta.stilles.rs/cms/wp-content/uploads/2024/08/lesonit.jpg",
      "content": "<p>U ponudi još imamo:</p>\n<ul>\n<li>građevinske ploče OSB, Qsb</li>\n<li>sirova iverica</li>\n<li>šper ploče</li>\n<li>furnirane ploče</li>\n<li>vodootporni šper – blažujka</li>\n</ul>\n"
    },
    "subcategories": []
  },
  {
    "id": 59,
    "count": 6,
    "description": "Postforming frontovi se izrađuju od iverice presvučene HPL laminatom, potom zaobljene 2 strane. Tako dobijamo ploče u standardnim širinama: 300, 400, 450 i 600mm.",
    "name": "Postforming frontovi",
    "slug": "postforming-frontovi",
    "parent": 0,
    "acf": {
      "card": "https://beta.stilles.rs/cms/wp-content/uploads/2024/08/MG_0654-pr-Small.jpg",
      "content": "<p>Sve neophodne komponente za proizvodnju postforminga su visokog kvaliteta, i nabavljaju se isključivo iz inostranih izvora. Savremena tehnologija, pored visokog kvaliteta sastavnih komponenti, garantuje kvalitet postforming ploča, koje mogu da odgovore zahtevima savremenog tržišta.</p>\n<p>Sopstvena proizvodnja STILLES-a omogućava konstantno i kontinuirano snadbevanje, zahvaljujući velikom lageru sirovina.</p>\n<p>Postforming ploče su otporne na habanje, visoku temperaturu i vlagu (uz prethodno adekvatnu montažu i zaštitu koju zahtevaju).</p>\n<p>Dužina tabli je 3050 mm ( u maloprodaji table sečemo na tačnu meru ).</p>\n"
    },
    "subcategories": []
  },
  {
    "id": 55,
    "count": 20,
    "description": "Oplemenjena iverica",
    "name": "Univer ploče",
    "slug": "univer",
    "parent": 0,
    "acf": {
      "card": "https://beta.stilles.rs/cms/wp-content/uploads/2024/08/univer-cover-photo.jpg",
      "content": "<p>Univer ploče pružaju veliku mogućnost oblikovanja po želji i odličnu izdržljivost.<br />\nPrimenljiv kako u kućnim enterijerima, tako i za široke komercijalne svrhe, kao što su: hoteli, zdravstvene ustanove, banke, saloni…<br />\nVršimo uslužno sečenje i kantovanje univer ploča.<br />\nTakođe imamo odgovarajuće kant trake za sve dezene univera.<br />\nDirektni smo zastupnici i distributeri Kastamonu grupacije.</p>\n"
    },
    "subcategories": [
      {
        "term_id": 65,
        "name": "Boje",
        "slug": "boje",
        "term_group": 0,
        "term_taxonomy_id": 65,
        "taxonomy": "kategorije",
        "description": "description here",
        "parent": 55,
        "count": 4,
        "filter": "raw",
        "acf": {
          "card": "https://beta.stilles.rs/cms/wp-content/uploads/2024/06/27121.jpg"
        },
        "content": "<p>sdsds</p>\n"
      },
      {
        "term_id": 64,
        "name": "Drvo",
        "slug": "drvo",
        "term_group": 0,
        "term_taxonomy_id": 64,
        "taxonomy": "kategorije",
        "description": "",
        "parent": 55,
        "count": 12,
        "filter": "raw",
        "acf": {
          "card": "https://beta.stilles.rs/cms/wp-content/uploads/2024/06/34032.jpg"
        },
        "content": ""
      },
      {
        "term_id": 66,
        "name": "Fantazije",
        "slug": "fantazije",
        "term_group": 0,
        "term_taxonomy_id": 66,
        "taxonomy": "kategorije",
        "description": "",
        "parent": 55,
        "count": 4,
        "filter": "raw",
        "acf": {
          "card": "https://beta.stilles.rs/cms/wp-content/uploads/2024/08/fantazija.jpg"
        },
        "content": ""
      }
    ]
  }
]
export type PlocastiParent = typeof plocastiParent
